import React from "react";
import classNames from "classnames";
import { ModuleCard, SolutionCard, Card } from "@molecules";
import { Container } from "@atoms";

const CardGrid = ({ cards, overlap, horizontal }) => {
  const classes = "w-full md:w-auto";

  return (
    <Container
      className={classNames({
        "md:-mt-40": overlap,
      })}
    >
      <div className="flex flex-wrap justify-center gap-5">
        {cards?.map((card, i) => {
          const { type, uid } = card;
          switch (type) {
            case "modules":
              return (
                <div
                  className={classNames(
                    "group sm:flex-[0_1_calc(50%-.625rem)] lg:flex-[0_1_calc(33.333%-.875rem)]",
                    {
                      "lg:flex-[0_1_calc(25%-.9375rem)]": !horizontal,
                    },
                    classes
                  )}
                  key={uid}
                >
                  <ModuleCard horizontal={horizontal} {...card} />
                </div>
              );
            case "solutions":
              return (
                <div
                  className={classNames(
                    "sm:flex-[0_1_calc(50%-.625rem)] lg:flex-[0_1_calc(33.333%-.875rem)]",
                    classes
                  )}
                  key={uid}
                >
                  <SolutionCard {...card} />
                </div>
              );
            default:
              return (
                <div
                  className={classNames(
                    "md:min-h-[12rem] md:flex-[0_1_calc(50%-.625rem)] lg:min-h-[16rem] lg:flex-[0_1_calc(33.333%-.875rem)]",
                    classes
                  )}
                  key={uid}
                >
                  <Card className={classes} {...card} />
                </div>
              );
          }
        })}
      </div>
    </Container>
  );
};

CardGrid.defaultProps = {};

export default CardGrid;
